import React from "react"
// import { Link } from "gatsby"
import Helmet from "react-helmet"

import "../styl/main.styl"

const Layout = ({ location, title, children, page }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let subhead

  if (location.pathname === rootPath) {
    subhead = (
      <h4 className="subhead">is a human approach to marketing <br />for people who would rather make what they love <br /> than persuade people to buy it</h4>
    )
  }
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/hcy3mhl.css" />
        <body className={page} />
      </Helmet>

      <header>
        <div className="menu" id="nav">
          <div className="navigation header-has-logo">
            <div className="cf">
              <h1 className="pull-left">
                <a className="logo gather-logo transition transition-fadeout" href="/"> Gather the People</a>
              </h1>

              <nav className="pull-right top-nav top-nav-light" role="navigation">

                {/* Collapsed nav, hidden for now

                <input className="menu-toggle" id="menu-toggle" type="checkbox" />
                <label className="pull-right" htmlFor="menu-toggle">Menu</label>

                */}

                <ul className="list-unstyled list-inline no-margin-bottom cf">
                  <li>
                    <a href="#purchase">Purchase</a>
                  </li>
                  <li>
                    <a href="#subscribe">Subscribe</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="banner" role="banner">
          <div className="container cf">
            <h2 className="headline-main">Gather the People</h2>
            {subhead}
          </div>
        </div>
      </header>

      <main>{children}</main>

      <footer className="site-footer" role="contentinfo">
        <div className="container cf">

          <a className="logo gtp-mark transition transition-fadeout pull-left" href="/">Gather the People</a>
          
          <div className="pull-left">
            <nav role="navigation">
              <ul className="list-inline list-unstyled">
                <li>
                  <a href="https://andyet.com">A project by &yet</a>
                </li>
                <li>
                  <a href="#subscribe">Subscribe</a>
                </li>
                <li>
                  <a href="/privacy">Privacy</a>
                </li>
                <li>
                  <a href="https://www.instagram.com/gatherthepeople/">@gatherthepeople</a>
                </li>
              </ul>

              <ul id="contact-info" className="list-inline list-unstyled">
                <li>
                  <a href="https://www.google.com/maps/place/723+The+Parkway,+Richland+WA+99352">723 The Parkway Suite &, Richland WA 99352</a>
                </li>
                <li>
                  <a href="tel:+15094166186">+1 509 416 6186</a>
                </li>
              </ul>
            </nav>

            <p className="farewell">Have a nice day</p>
          </div>
        </div>
      </footer>

    </>
  )
}

export default Layout
